import React from "react";
import Layout from "../../layouts";

import './style.css'

const PrivacyPolicyEn = () => {
    return (
        <Layout children="en" showNav={false}>
            <h1>Photo-Histogram-Game – Privacy Policy</h1>
            <h2>Used Personal Data</h2>
            <p>
                All data that is used within the app is only stored on the user's device. No data is transmitted to any
                processor.
            </p>

            <h2>User Statistics</h2>
            <p>
                Anonymous user statistics are collected (game start, game end). This helps us improve the app.
                This information is not shared with third parties.
            </p>

            <h2>Questions</h2>
            <p>
                For any questions you can send me an email to galler.stefan@gmail.com.
            </p>
        </Layout>
    )
}

export default PrivacyPolicyEn
